import { createSlice } from "@reduxjs/toolkit";

const initialNews = localStorage.getItem('news');

const initialState = {
    user: null,
    news: initialNews?JSON.parse(initialNews):{},
};

const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        signIn: (state, action) => {
            state.user = { ...action.payload };
        },

        signOut: (state) => {
            state.user = null;
        },

        likeAction: (state, action) => {
            state.news[action.payload.id] = state.news[action.payload.id] == 'L'?undefined: 'L';
            localStorage.setItem('news', JSON.stringify(state.news));
        },

        dislikeAction: (state, action) => {
            state.news[action.payload.id] = state.news[action.payload.id] == 'D'?undefined: 'D';
            localStorage.setItem('news', JSON.stringify(state.news));
        },
    },
});

export const { signIn, signOut, likeAction, dislikeAction } = userSlice.actions;
export default userSlice.reducer;
